<template>
	<div class="vimeo-wrapper">
		<div id="video-content"></div>
	</div>
</template>
<script>
import { ref, onMounted } from '@vue/composition-api'
import Player from '@vimeo/player'

import { useRouter } from '@core/utils'

export default {
	setup() {
		const { route } = useRouter()
		const vimeoId = ref(route.value.query.id)
		const timeWatched = ref(parseInt(route.value.query.time_watched) || 0)

		const loadVimeo = () => {
			try {
				const options = {
					id: vimeoId.value,
					autopause: false,
					width: screen.width,
					height: screen.height,
				}
				const player = new Player('video-content', options)

				player.on('timeupdate', data => {
					if (
						data.seconds - 1 < timeWatched.value &&
						data.seconds > timeWatched.value
					) {
						timeWatched.value = data.seconds
					}
				})

				player.on('seeked', data => {
					if (timeWatched.value < data.seconds) {
						player.setCurrentTime(timeWatched.value)
					}
				})
			} catch (e) {
				console.error(e)
			}
		}

		onMounted(() => {
			loadVimeo()
		})

		return {}
	},
}
</script>
